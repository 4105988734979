<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">

      <el-form-item prop="member_id" label="会员等级" label-width="100px">
        <el-select v-model="formData.user_member.member_id" placeholder="请选择">
          <el-option v-for="item in memberList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="nickname" label="昵称">
        <el-input size="small" v-model="formData.nickname" placeholder="昵称"></el-input>
      </el-form-item>

      <el-form-item prop="realname" label="真实姓名">
        <el-input size="small" v-model="formData.realname" placeholder="真实姓名"></el-input>
      </el-form-item>

      <el-form-item prop="mobile" label="手机号码">
        <el-input size="small" v-model="formData.mobile" placeholder="真实姓名"></el-input>
      </el-form-item>

      <el-form-item label="封面图" prop="cover">
        <UploaderImg :url.sync="formData.face"></UploaderImg>
      </el-form-item>

      <el-form-item label=" ">
        <el-button size="small" type="" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { detail, editUser, getAllMember } from './api'
import { DICT_SYSTEM_SEX, DICT_SYSTEM_STATUS } from '@/enum/dict'
import Uploader from '@/components/uploader/index.vue'
import UploaderImg from '@/components/uploader/uploader-img.vue'

export default {
  name: 'AddOrEdit',
  components: { UploaderImg },
  data() {
    return {
      options: [],
      DICT_SYSTEM_SEX,
      DICT_SYSTEM_STATUS,
      memberList: [
                {
                    id: 0,
                    title: '请选择'
                }
       ],
      formData: {
        face: '',
        nickname: '',
        realname: '',
        mobile: '',
        from: '',
        user_member: {
          member_id: 0
        }
      },
      rules: {
        realname: [
          {
            required: true,
            message: '真实姓名',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    if (this.$route.params.id) this.getDetail()
    this.getAllMember()
  },

  methods: {
    updateSex(val) {
      this.formData.sex = val
    },
    updateStatus(val) {
      this.formData.status = val
    },
    getUploadUrl(imageUrl) {
      this.formData.cover = imageUrl
    },
    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formData.id) {
            // 编辑
            await editUser(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },
    async getDetail() {
      this.formData = await detail(this.$route.params.id)
      console.log(this.formData)
    },
    async getAllMember() {
      this.memberList = await getAllMember({})
    },
    cancel() {
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
